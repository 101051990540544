$(function(){
if (!sessionStorage.isVisited) {
  sessionStorage.isVisited = 'true'
  $(function(){
     setTimeout(function() {
        $('#splash').fadeOut( 1800, 'linear');
      }, 2000);
  });
} else {
    $(function(){
      $("#splash").hide()
      });
    };
});
